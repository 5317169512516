import { Injectable} from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ConfigurationService } from '@central/ng-shared';
import { User, Billing, Payment} from '../models';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import moment from 'moment';
import { Observable, throwError, forkJoin } from 'rxjs';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import _debounce from 'lodash/debounce';

@Injectable()
export class AmpOpApiService {
	public apiUrl = '';
	public opApiUrl = '';
	public headers = {}
	public headeroptions = {}
	public ampConnectCookie;
	public states: any = [];
	public saveRequest = 1;
	public loadedFromOrderSession = false;
	public notAllowedTypes = ['vps','legacy','dedicated'];
	public cookieHostname = '.inmotionhosting.com';
	public saveCartDebounce: any;
	public emailAddress = null;

	public constructor(
		public apiService: ApiService,
		public configService: ConfigurationService,
		public localStorageService: AmpLocalStorageService,
		public cookieService: CookieService
	) {
		this.apiUrl = this.configService.config.powerPanel.ampHost;
		this.opApiUrl = this.configService.config.powerPanel.opHost;
		this.cookieHostname = this.configService.config.powerPanel.cookieHostname;
		this.headeroptions = {
			'Content-Type':'application/json; charset=utf-8',
			'withCredentials' : true
		}
		this.ampConnectCookie = this.cookieService.get('AmpConnect');

		this.saveCartDebounce = _debounce((packages: any, data: any) => {
			this.saveCartCall(packages,data);
		}, 1000);
	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getStoredDataFeed() {
		return this.localStorageService.getItem('op-data-feed');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setStoredDataFeed(catalog) {
		catalog.ttl = moment().add(1,'days')
		this.localStorageService.setItem('op-data-feed',catalog);
		return catalog;
	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getCartCatalog() {
		const catalog = this.localStorageService.getItem('op-catalog');
		if(catalog !== undefined) {
			catalog['data'] = Object.keys(catalog.data).map((key) => catalog.data[key]);
			return catalog;
		} else {
			return {};
		}
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setCartCatalog(catalog) {
		const data = {
			data: catalog,
			ttl: moment().add(1,'days')
		}
		this.localStorageService.setItem('op-catalog',data);
		return data;
	}

	/**
	 * Get Data Feed Params.
	 *
	 * @return Object   Data Feed.
	 */
	public getDataFeedParams() {
		return this.localStorageService.getItem('op-data-feed-params');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setDataFeedParams(params) {
		this.localStorageService.setItem('op-data-feed-params',params);

	}

	/**
	 * Get Cart Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredCart() {
		let cart = this.localStorageService.getItem('op-cart');
		if(cart !== undefined) {
			cart = this.setCartEmail(cart);
			return cart;
		} else {
			return {};
		}
	}

	public setCartEmail(cart) {
		if(cart.hasOwnProperty('EmailAddress')){
			if(cart['EmailAddress'] !== null) {
				this.emailAddress = cart.EmailAddress
			} else {
				cart['EmailAddress'] = this.emailAddress;
			}
		} else {
			cart['EmailAddress'] = this.emailAddress;
		}
		return cart;
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredCart(cart) {
		cart.ttl = moment().add(90,'days')
		cart = this.setCartEmail(cart);
		this.localStorageService.setItem('op-cart',cart);
		const cookieHostname = this.cookieHostname;
		const ttl = new Date();
		ttl.setTime(
			ttl.getTime() + 4*60*60000 /* 4 hours in milliseconds */
		);
		this.cookieService.set('CentralOPCid', cart.Id, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('cid', cart.Id, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('CentralOPCsk', cart.CustomerSessionKey, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('csk', cart.CustomerSessionKey, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('CentralOPRefId', cart.RefId, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('refid', cart.RefId, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
	}
	
	/**
	 * Get Domain Info from Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @return Domain info.
	 */
	public getStoredDomainInfo() {
		const domainInfo = this.localStorageService.getItem('op-domain');
		if(domainInfo !== undefined) {
			return domainInfo;
		} else {
			return {};
		}
	}

	/**
	 * Store DomainInfo in Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @param cart Domain info.
	 */
	public setStoredDomainInfo(domainInfo) {
		domainInfo.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-domain',domainInfo);
	}

	/**
	 * Get Account Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredAccount() {
		const account = this.localStorageService.getItem('op-account');
		if(account !== undefined) {
			return account;
		} else {
			return {};
		}
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredAccount(account) {
		account.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-account',account);
	}


	public setPackageInfo(packages) {
		this.localStorageService.setItem('op-package-info',packages);
	}
	public setOrderInfo(affiliate = null, campaign = null, referrer = null) {
		const params = {
			affiliate: affiliate,
			campaign: campaign,
			referrer: referrer,
		}
		this.localStorageService.setItem('op-order-info',params);
	}

	public getPackageInfo() {
		return this.localStorageService.getItem('op-package-info');
	}

	public setStoredEvents(events) {
		this.localStorageService.setItem('op-events-info',events);
	}

	public getStoredEvents() {
		const events = this.localStorageService.getItem('op-events-info');
		if(events !== undefined) {
			return events;
		} else {
			return [];
		}
	}

	public getOrderInfo() {
		return this.localStorageService.getItem('op-order-info');
	}

	/**
	 * Delete cart/datafeed info in Local Storage.
	 *
	 */
	public clearStoredInfo() {
		this.localStorageService.removeItem('op-cart')
		this.localStorageService.removeItem('op-package-info')
		this.localStorageService.removeItem('op-campaign-info')
		this.localStorageService.removeItem('op-affiliate-info')
		this.localStorageService.removeItem('op-events-info')
		this.localStorageService.removeItem('op-account')
		this.localStorageService.removeItem('op-data-feed')
		this.localStorageService.removeItem('op-data-feed-params')
		this.localStorageService.removeItem('op-catalog')
		const cookieHostname = this.cookieHostname;
		this.cookieService.delete('CentralOPCid','/',cookieHostname);
		this.cookieService.delete('CentralOPCsk','/',cookieHostname);
		this.cookieService.delete('CentralOPRefId','/',cookieHostname);
		this.cookieService.delete('cid','/',cookieHostname);
		this.cookieService.delete('csk','/',cookieHostname);
		this.cookieService.delete('refid','/',cookieHostname);
	}

	public clearEventsInfo() {
		this.localStorageService.removeItem('op-events-info')
	}

	public getOPCatalogFromCartInfo(csk, orderSession = false) {
		const vm = this;
		this.loadedFromOrderSession = orderSession;
		return new Observable((observer) => {
			if(csk) {
				vm.initializeCart(csk).subscribe(cart => {
					if(cart['success'] === true) {
						vm.setStoredCart(cart)
						const cartItemIds = Object.keys(cart['Items']);
						const packageStrings = [];
						cartItemIds.forEach(cartItemId => {
							const packageId = cart['Items'][cartItemId].PackageId;
							const productId = cart['Items'][cartItemId].ProductId;
							const packageString = packageId + '-' + productId;
							packageStrings.push(packageString)
						})
						const packageArray = this.parsePackageInfo(packageStrings.join(','))
						this.getOPCatalog(packageArray)
							.subscribe(dataFeed => {
								const data = this.filterPackagesFromCart(dataFeed,cart)
								if(data.hasOwnProperty('redirect')) {
									observer.next(data);
								} else {
									const result = {
										dataFeed: dataFeed,
										packages: data,
										packageInfo: packageArray,
										cart: cart
									}
									observer.next(result);
								}
								observer.complete();
							})
						
					} else {
						observer.next('Unable to get data feed');
						observer.error();
					}
				})
			} else {
				observer.next('Unable to get data feed');
				observer.error();
			}
			
		})

	}

	public filterPackagesFromCart(dataFeed,cartInfo) {
		let redirectToOldCart = false;
		const data = {
			packages: [],
			terms: [],
			term: "",
			packageFound: true,
			freeDomain: false,
			requiresDomain: false,
			domainName: null,
			purchasedDomain: ""
		}
		const selectedAddons = [];
		let selectedTerm = "";
		const items = Object.keys(cartInfo.Items).map((key) => cartInfo.Items[key])
		const availableProducts = Object.keys(cartInfo.AvailableProducts).map((key) => cartInfo.AvailableProducts[key])
		const selectedPkgs = items.filter(e => !e.hasOwnProperty('PlanId'));
		const selectedPkgIds = selectedPkgs.map(e => parseInt(e.ProductId,10));
		if(this.loadedFromOrderSession) {
			if(availableProducts.filter(e => selectedPkgIds.includes(parseInt(e.Id,10)) && this.notAllowedTypes.includes(e.SubType.toLowerCase())).length > 0) {
				redirectToOldCart = true;
			}
		}
		if(redirectToOldCart) {
			return {redirect: true};
		} else {
			const pkg = selectedPkgs.reduce((a, b) => a.UpdatedAt > b.UpdatedAt ? a : b);
			const info = dataFeed.data;
			const cartProduct = availableProducts.find(e => pkg.ProductId === e.Id);
			const products = info.filter(p => p.ProductName.toLowerCase().includes(cartProduct.Name.toLowerCase()) && parseInt(p.PackageId,10) === pkg.PackageId);

			for (let n = 0; n < products.length; n++) {
				const product = products[n];
				product.cartItemId = pkg['Id'];
				const catalogPackage = this.createCatalogPackage(product);
				
				if(catalogPackage['info'].PackageRequiresDomain) {
					data.requiresDomain = catalogPackage['info'].PackageRequiresDomain;
				}
				if(pkg.hasOwnProperty('Addon')) {
					const cartAddons = Object.keys(pkg['Addon']).map((key) => pkg['Addon'][key])
					cartAddons.forEach(cartAddon => {
						const cartAddonProduct = catalogPackage['addons'].find(p => p.AddonDetailsId === cartAddon.ProductId)
						if(cartAddonProduct) {
							cartAddonProduct.cartItemId = cartAddon['Id'];
							const addon = this.createCatalogPackage(cartAddonProduct);
							addon['selected'] = true;
							addon['planParent'] = catalogPackage['planCode'];
							addon['cartItemId'] = cartAddon['Id'];
							selectedAddons.push(addon)
						}
					})
				}
				if(pkg.hasOwnProperty('OwnedDomain') && data.domainName === null) {
					catalogPackage['options']['domain_name'] = pkg['OwnedDomain'];
					data.domainName = pkg['OwnedDomain'];
				}
				if(pkg.hasOwnProperty('PrimaryDomainId') && data.domainName === null) {
					const cartDomain = items.find(e => parseInt(e.Id,10) === parseInt(pkg.PrimaryDomainId,10));
					if(cartDomain) {
						data['purchasedDomain'] = cartDomain.Domain;
						catalogPackage['options']['domain_name'] = cartDomain.Domain;
						data.domainName = cartDomain.Domain;
					}
				}
				if(pkg.hasOwnProperty('DataCenter')) {
					catalogPackage['options']['data_center'] = pkg['DataCenter'];
				}
				if(pkg.hasOwnProperty('AutoInstall')) {
					catalogPackage['options']['autoinstall'] = pkg['AutoInstall'];
				}
				if(catalogPackage.type === 'domain') {
					catalogPackage['info'] = cartProduct;
				}
				catalogPackage['cartItemId'] = pkg['Id'];
				if((catalogPackage['type'] === 'hosting' && parseInt(product.PackageToProductOfferingId,10) === pkg.ProductId) || catalogPackage['type'] !== 'hosting'){
					data.packages.push(catalogPackage);
					if(selectedTerm === "") {
						selectedTerm = catalogPackage.planCode;
					}
					data.packages = data.packages.concat(selectedAddons.filter(e => e.planParent === catalogPackage.planCode));
				}

				if(catalogPackage.type === 'hosting') {
					data.terms.push(catalogPackage)
				}
			}
			data.term = selectedTerm;
			return data;
		}
	}


	public getOPCatalogFromPackageString(packages) {
		return new Observable((observer) => {
			this.getOPCatalog(packages)
				.subscribe(dataFeed => {
					const data = this.filterPackages(dataFeed,packages);
					if(data.packageFound === true) {
						const result = {
							dataFeed: dataFeed,
							packages: data,
							packageInfo: packages,
						}
						observer.next(result);
						observer.complete();
					} else {
						observer.next('Unable to get data feed');
						observer.error();
					}
				})
		})

	}

	/**
	 * Get Raw Product Data Feed from IMHOP and Filter.
	 *
	 * Store filtered data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
    public getOPCatalog(packages) {
		return new Observable((observer) => {
			if(packages.filter(p=>p.hasOwnProperty('packageString')). length > 0) {
				let updateDataFeed = true;
				let dataFeed = this.getStoredDataFeed();
				if (dataFeed) {
					const sameParams = this.samePackageInfo(packages);
					if(sameParams === true) {
						if(moment().toISOString() < moment(dataFeed.ttl).toISOString()) {
								updateDataFeed = false;
								observer.next(dataFeed);
								observer.complete();
						}
					} else {
						//Only use current package for now.
						// Save this for when allowing multiple
						/* const currentPackages = this.getPackageInfo();
						if(currentPackages) {
							packages = [...currentPackages,...packages];
						}
						this.setPackageInfo(packages); */
					}
				}
				this.setPackageInfo(packages);
				if(updateDataFeed) {
					const providers = [];
					for (let i = 0; i < packages.length; i++) {
						const pkg = packages[i];
						const opParams = {};
						opParams['packageId'] = pkg.packageId;
						opParams['productId'] = pkg.productId;
						opParams['includeAddons'] = true;
						const newProvider = {
							params: opParams,
						}
						providers.push(newProvider)
					}
					
					forkJoin(
						providers.map(p => this.getDataFeed(p.params))
					).subscribe(
						(allResults) => {
							let result = [];
							for( const i in allResults) {
								if(allResults[i].hasOwnProperty('data')) {
									result = result.concat(allResults[i]['data'].filter(
										item2 => !result.some(item1 => 
												item1.PackageId === item2.PackageId &&
												item1.PackageToProductOfferingId === item2.PackageToProductOfferingId
											)
										)
									);
								}
							}
							dataFeed = this.setStoredDataFeed({data:result})
							observer.next(dataFeed);
							observer.complete();
						},
						(resp) => {
							observer.next('Unable to get data feed');
							observer.error(resp);
						}
					)

				}
			}
		})
	}

	public parsePackageInfo(packages) {
		const packagesArray = packages.split(',')
		return packagesArray.map(p => {
			const packageArray 	= p.split('-')
			const productId = packageArray.pop();
			const packageId = packageArray.pop();
			return {
				packageString: p,
				packageId: parseInt(packageId,10),
				productId: parseInt(productId,10)
			}
		})
	}

	/**
	 * Get Product Data Feed from IMHOP.
	 * @param  params   Package and Product Info. Affiliate and/or campaign info.
	 */
	public getDataFeed(params) {
		const url = this.opApiUrl + '/api/product-catalog/data-feed';
		return new Observable((observer) => {
		this.apiService.http.post(url, params, this.headeroptions)
			.subscribe( 
				(catalog) => {
					observer.next(catalog);
					observer.complete();
				},
				(resp) => {
					observer.next('Unable to get data feed');
					observer.error(resp);
				}
			);
		})
	}

	/**
	 * Get Domain Data Feed from IMHOP.
	 *
	 * Store data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public checkDomainInfo() {
		return new Observable((observer) => {
			const domainInfo = this.getStoredDomainInfo();
			if(domainInfo.hasOwnProperty('data')) {
				observer.next(domainInfo);
				observer.complete();
			} else {
				const opParams = {};
				opParams['packageId'] = 39;
				opParams['productName'] = 'Domain Privacy';
				opParams['includeAddons'] = true;

				const url = this.opApiUrl + '/api/product-catalog/data-feed';

				this.apiService.http.post(url, opParams, this.headeroptions)
					.subscribe( 
						(catalog) => {
							this.setStoredDomainInfo(catalog);
							observer.next(catalog);
							observer.complete();
						},
						(resp) => {
							observer.next('Unable to get domain info');
							observer.error();
						}
					);
			}
		});
		
	}

	/**
	 * Search data feed for package info.
	 * 
	 * @param packages package and product string.
	 */
	public filterPackages(dataFeed, packages) {
		const data = {
			packages: [],
			terms: [],
			term: "",
			packageFound: true,
			freeDomain: false,
			requiresDomain: false
		};
		let selectedPackage = {}
		const selectedAddons = [];
		let selectedTerm = "";
		for (let i = 0; i < packages.length; i++) {
			const pkg = packages[i];
			const info = dataFeed.data;
			const mainProduct = info.find(e => pkg.productId === e.PackageToProductOfferingId);
			const products = info.filter(p => p.ProductName.toLowerCase().includes(mainProduct.ProductName.toLowerCase()) && parseInt(p.PackageId,10) === pkg.packageId)

			for (let n = 0; n < products.length; n++) {
				const product = products[n];
				const catalogPackage = this.createCatalogPackage(product);
				if(parseInt(product.PackageToProductOfferingId,10) === pkg.productId){
					data.requiresDomain = catalogPackage['info'].PackageRequiresDomain
					selectedPackage = catalogPackage;
					selectedTerm = catalogPackage.planCode;
					catalogPackage['addons'].forEach(v => {
						if(v.AddonAutoSelected) {
							const addon = this.createCatalogPackage(v);
							addon['selected'] = true;
							addon['planParent'] = selectedTerm;
							selectedAddons.push(addon)
						}
					})
					data.packages.push(selectedPackage)
					data.packages = [...data.packages, ...selectedAddons];
				}
				data.terms.push(catalogPackage)
			}
		}
		data.term = selectedTerm;
		return data;
	}

	public createCatalogPackage(product, domain = null) {
		const price = parseFloat(product.Price) * 100;
		let termType = product.TermType;
		if(parseInt(product.TermLength,10) > 1) {
			termType += "s";
		}
		const catalogPackage = {
			"label": product.LegacyName,
			"code": product.PackageId +'_'+product.PackageToProductOfferingId,
			"planCode": product.PackageId +'_'+product.PackageToProductOfferingId,
			"packageId": product.PackageId,
			"productId": product.PackageToProductOfferingId,
			"plan_interval_length": product.TermLength,
			"plan_interval_unit": termType,
			"plan_interval_unit_display": termType.charAt(0).toUpperCase() + termType.slice(1),
			"price_in_cents": price,
			"type": product.ProductType.split(':')[0].toLowerCase(),
			"selected": false,
		}
		if(product.hasOwnProperty('AddonDetailsId')) {
			catalogPackage['productId'] = product['AddonDetailsId'];
			catalogPackage['code'] = product.PackageId +'_'+catalogPackage['productId'];
			catalogPackage['planCode'] = product.PackageId +'_'+catalogPackage['productId'];
		}
		if(product.hasOwnProperty('cartItemId')) {
			catalogPackage['cartItemId'] = product.cartItemId;
			catalogPackage['planCode'] = catalogPackage['planCode'] + "_" + product.cartItemId;
			catalogPackage['code'] = catalogPackage['planCode'];
		}
		catalogPackage['addons'] = [];
		if(product.hasOwnProperty('Addons')) {
			product.Addons = product.Addons.filter(addon => {
				if(['both','op'].includes(addon.Availability)) {
					return true;
				}
			});
			catalogPackage['addons'] = product.Addons;
		}
		if(product.hasOwnProperty('Addon')) {
			product.Addons = Object.keys(product.Addon).map((key) => product.Addon[key]);
			catalogPackage['addons'] = product.Addons;
		}
		catalogPackage["info"] = product;
		catalogPackage["transaction_type"] = "recurring";
		catalogPackage["quantity"] = product.hasOwnProperty('quantity') ? product['quantity'] : 1;
		catalogPackage["options"] = {
			"allows_multiple": product.AllowedQuantity > 1,
			"allows_term_discount": false
		}
		if(domain) {
			catalogPackage["options"]['domain_name'] = domain;
		}
		catalogPackage["is_recurring"] = false;
		const originalPrice = parseFloat(product.Price);
		if(product.hasOwnProperty('DiscountedPrice')) {
			product.DiscountPrice = product.DiscountedPrice;
		}
		const discountPrice = parseFloat(product.DiscountPrice);
		if(originalPrice !== discountPrice) {
			let discount = 0;
			if(discountPrice < originalPrice) {
				discount = originalPrice - discountPrice;
			}
			if(discount > 0) {
				catalogPackage['discounts'] = {
					type:"dollars",
					amount: discount
				}
			}
		}
		return catalogPackage;
	}

	/**
	 * Make api call to create op cart info.
	 * Store info in local storage.
	 * 
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public createCart(packages) {
		const vm = this;
		const params = this.getOrderInfo();
		return new Observable((observer) => {
			const campaignHandle = params.campaign ? params.campaign : null;
			const storedCart = this.getStoredCart();
			let cartCall;
			let irid = this.cookieService.get('irid');
			if(params.affiliate) {
				irid = params.affiliate;
			}
			const items = [];
			let url;
			for(let i=0; i < packages.length; i++) {
				const itemData = {
					PackageId: packages[i].packageId,
					ProductId: packages[i].productId
				}
				items.push(itemData);
			}

			const cartData = {
				CampaignHandle: campaignHandle,
				ImpactRadiusId: irid,
				Items: items,
				Referrer: params.referrer
			}

			if(storedCart.hasOwnProperty('CustomerSessionKey') === false) {
				url = this.opApiUrl + '/api/cart';
				cartCall = vm.apiService.http.post(url, cartData, this.headeroptions)
				cartCall.subscribe( 
					(cart) => {
						cart.Items = cartData.Items;
						vm.setStoredCart(cart);
						this.fetchAmpCatalog(cart.CustomerSessionKey).subscribe();
						this.initializeCart(cart.CustomerSessionKey)
							.subscribe( initCart => {
								vm.setStoredCart(initCart)
							})
						observer.next(cart)
						observer.complete()
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					}
				);
			} else {
				cartCall = this.initializeCart(storedCart.CustomerSessionKey).subscribe( 
					(cart) => {
						if(cart['success'] === true) {
							vm.setStoredCart(cart);
							this.fetchAmpCatalog(storedCart.CustomerSessionKey).subscribe();
							observer.next(cart)
							observer.complete()
						} else {
							this.clearStoredInfo()
							observer.next('failed')
							observer.error()
						}
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					})
				//this.fetchAmpCatalog().subscribe();
			}
		});
	}

	public initializeCart(csk) {
		const url = this.opApiUrl + '/api/order/initialize';
		const data = {
			CustomerSessionKey: csk
		}
		return this.apiService.http.post(url, data, this.headeroptions)
	}

	public updateCartData(csk) {
		this.initializeCart(csk).subscribe(cart => {
			if(cart['success'] === true) {
				this.setStoredCart(cart)
			}
		})
	}
	
	/**
	 * Make purchase api call to IMHOP.
	 * Build purchase request
	 * 
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public purchase(data) {
		const vm = this;
		const url = this.opApiUrl + '/api/order/purchase'
		const request = this.buildPurchaseRequest(data);
		return vm.apiService.http.post(url, request, this.headeroptions)
	}

	/**.
	 * Build purchase request
	 * 
	 * @param  data Cart and User Info.
	 * @return  request object.
	 */
	private buildPurchaseRequest(data) {
		const matomoInfo = this.getMatomoInfo();
		let irid = this.cookieService.get('irid');
		const order = this.getOrderInfo();
		if(order) {
			if(order.affiliate) {
				irid = order.affiliate;
			}
		}
		const cart = this.getStoredCart();
		let affiliateInfo = {};
		if(irid) {
			affiliateInfo = {
				Id: irid,
				RefId: cart.RefId
			}
		}
		const request = {
			Items:              [],
			User:               <User>{},
			Billing:            <Billing>{},
			Payment:            <Payment>{},
			Affiliate:          affiliateInfo,
			//Webpro:             {
			//	Banner:    null,
			//	Id:        0,
			//	Register:  false,
			//},
			CustomerSessionKey: cart.CustomerSessionKey,
			OrderSource: 'central',
			Events:             "",
			"MatomoInfo": matomoInfo
		};
		const addons = [];
		let mainItem = {};
		let domainItem = null;
		let domain = data.domainInfo.name;
		let domainPrivacy = null;
		let id = 320;
		data.items.forEach( cartItem => {
			const item = {
				Id:			id,
				ProductId:  cartItem.productId,
				Quantity:   cartItem.quantity,
			}
			if(cartItem.type === 'hosting'){
				item['PackageId'] = cartItem.packageId;
				domain = cartItem.domain;
				mainItem = item;
			}
			if(cartItem.type === 'domain') {
				item['Domain'] = cartItem.domain;
				item['Free'] = data.domainInfo.freeDomain;
				item['UseDomainCredit'] = data.domainInfo.freeDomain;
				domainItem = item;
			}
			if(cartItem.label === 'Domain Privacy') {
				domainPrivacy = item;
			}
			if(cartItem.type === 'addon' && cartItem.label !== 'Domain Privacy') {
				addons.push(item)
			}
			id++;
		} );
		if(addons.length > 0) {
			mainItem['Addon'] = addons;
		}
		if(domainItem) {
			mainItem['PrimaryDomainId'] = domainItem.Id;
			domainItem['PlanId'] = mainItem['Id'];
			if(domainPrivacy) {
				domainItem['Addon'] = [domainPrivacy];
			}
		} else {
			mainItem['OwnedDomain'] = domain;
		}
		request.Items.push(mainItem);
		if(domainItem) {
			request.Items.push(domainItem);
		}
		let fullName;
		// User
		if(data.hasOwnProperty('user')) {
			delete request.Billing;
			const phonePrefix = data.user.dialing_code;
			const phoneNumber = data.user.phone.replace("+"+phonePrefix, '')

			request.User.FirstName        = data.user.first_name;
			request.User.LastName         = data.user.last_name;
			fullName = data.user.first_name + ' ' + data.user.last_name;
			request.User.Address1         = data.user.address1;
			request.User.Address2         = data.user.address2;
			request.User.City             = data.user.city;
			request.User.Country          = data.user.country; 
			request.User.Province         = data.user.state;
			request.User.CompanyName      = data.user.company_name;
			request.User.ContactType      = '';
			request.User.ContactTypeOther = null;
			request.User.CustomerType     = data.user.customerType.toLowerCase();
			request.User.Email            = data.user.email;
			request.User.Phone            = phoneNumber;
			request.User.PhonePrefix      = phonePrefix;
			request.User.Postal           = data.user.postal_code;
			request.User.Referred         = cart['Referrer'];
			request.User.SalesPersonId    = cart['SalesPersonId'];
			request.User.SalesPersonsId2  = cart['SalesPersonId2'];
			request.User.Password		  = data.user.password;
		}
		
		// Billing
		if(data.hasOwnProperty('billing')) {
			delete request.User;
			request.Billing.FirstName        = data.billing.first_name;
			request.Billing.LastName         = data.billing.last_name;
			fullName = data.billing.first_name + ' ' + data.billing.last_name;
			request.Billing.Address1         = data.billing.address1;
			request.Billing.Address2         = data.billing.address2;
			request.Billing.City             = data.billing.city;
			request.Billing.Country          = data.billing.country;
			request.Billing.Province         = data.billing.state;
			request.Billing.CompanyName      = data.billing.company_name;
			request.Billing.Email            = data.billing.email;
			request.Billing.Phone            = data.billing.phone;
			request.Billing.Postal           = data.billing.postal_code;
		}

		// Payment
		request.Payment.Method        = data.payment.method;
		if(data.payment.method === 'Credit Card') {
			if(data.payment.hasOwnProperty('id')) {
				request.Payment.Id = data.payment.id;
			} else {
				request.Payment.Method        = data.payment.method;
				request.Payment.Csv           = data.payment.cvv;
				request.Payment.Expiry        = data.payment.expiration;
				request.Payment.Name      	  = fullName;
				request.Payment.Number        = data.payment.card_number;
			}
		}
		const events = this.getStoredEvents();
		events[0]['CustomerSessionKey'] = cart.CustomerSessionKey;
		const billingEvent = {
			updateEvent: true,
			AccountId: "",
			event: "cart:billing",
			timeStamp: Date.now()
		}
		if(request.hasOwnProperty('User')) {
			billingEvent['AccountId'] = request.User.hasOwnProperty('AccountId') ? request.User['AccountId'] : "";
			billingEvent['User'] = {...request.User};
			if(billingEvent['User'].hasOwnProperty('Password')) {
				delete billingEvent['User']['Password'];
			}
		}
		if(request.hasOwnProperty('Billing')) {
			billingEvent['Billing'] = {...request.Billing};
			if(billingEvent['Billing'].hasOwnProperty('Password')) {
				delete billingEvent['Billing']['Password'];
			}
		}
		
		events.push(billingEvent);
		request.Events = JSON.stringify(events);
		return request;
	}

	/**
	 * Make authentication api call to IMHOP.
	 * Should get cookie
	 * 
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public cartAuthenticate = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				password: data.password,
			}

			const url = this.opApiUrl + '/api/user/authenticate'
			vm.apiService.http.post(url, request, this.headeroptions)
				.subscribe(resp => {
					if(resp.hasOwnProperty('authenticated')) {
						if(resp.authenticated === true) {
							if(resp.hasOwnProperty('access-token')) {
								const ttl = new Date();
								ttl.setTime(
									ttl.getTime() + 4*60*60000 /* 4 hours in milliseconds */
								);
								const cookieHostname = this.cookieHostname;
								this.cookieService.set('AmpConnect', resp['access-token'], {
									path: '/',
									domain: cookieHostname,
									expires: ttl,
									sameSite: 'None',
									secure: true
								});
							}
							this.ampConnectCookie = this.cookieService.get('AmpConnect');
							if(cart.hasOwnProperty('CustomerSessionKey')) {
								this.updateCartData(cart.CustomerSessionKey)
							}
							observer.complete();
						} else {
							observer.error('failed');
						}
					} else {
						observer.error('failed');
					}
				})

		})
    }

	/**
	 * Make has-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public checkTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			const url = this.opApiUrl + '/api/user/has-two-factor'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.hasOwnProperty('hasTwoFactor')) {
						if(['text','dormant'].includes(resp['type'])) {
							const codeRequest = {
								email: data.email,
								type: resp['type'],
								accountId: resp['accountId']
							}
							if(resp['type'] === 'dormant') {
								codeRequest['send'] = false;
								codeRequest['comment'] = true
							}
							this.sendVerificationCode(codeRequest).subscribe()
						}
						observer.next(resp)
						observer.complete();
						
					} else {
						observer.error(resp);
					}
				})

		})
    }

	/**
	 * Make verify-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public sendVerificationCode = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				accountId: data.accountId,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			let path = '/api/user/send-sms-auth'
			if(data['type'] === 'dormant') {
				path = '/api/user/send-one-time-code';
			}
			const url = this.opApiUrl + path;
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['status'] === '200') {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	public addNewCard = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const url = this.opApiUrl + '/api/new-card/undefined'
			vm.apiService.http.post(url, data, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['id']) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.next()
						observer.error();
					}
				},
				(error) => {
					observer.error(error);
				})

		})
	}

	/**
	 * Make verify-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public verifyTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				code: data.code,
				CustomerSessionKey: cart.CustomerSessionKey
			}

			const url = this.opApiUrl + '/api/user/verify-two-factor'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['success'] === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.next()
						observer.error();
					}
				},
				(error) => {
					observer.error(error);
				})

		})
    }

	/**
	 * Make forgot-password api call to IMHOP.
	 * 
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public forgotPassword = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				email: data.email,
				username: data.username,
				brand: this.configService.config.powerPanel.brandShortName,
			}

			const url = this.opApiUrl + '/api/cart/forgot-password'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.success === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error({error: resp.errors[0]});
					}
				})

		})
    }

	public isAuthenticated = function() {
		if(this.ampConnectCookie) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Make forgot-password api call to IMHOP.
	 * 
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public getBilling = function (id) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				PaymentId: id,
			}

			const url = this.opApiUrl + '/api/user/get-billing'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.hasOwnProperty('Billing')) {
						observer.next(resp['Billing'])
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	public getAccountInfo = function (cached = false) {
		const vm = this;
		return new Observable((observer) => {
			if(this.isAuthenticated()) {
				const cart = this.getStoredCart();
				const storedAccount = this.getStoredAccount();
				if(cached && storedAccount.hasOwnProperty('user')) {
					observer.next(storedAccount);
					observer.complete();
				} else {
					const request = {}

					const url = this.opApiUrl + '/api/user/get-account'
					vm.apiService.http.post(url, request, this.headeroptions)
						.pipe( catchError(error => throwError( error )))
						.subscribe ( resp => {
							if(resp.hasOwnProperty('User')) {
								const countryCode = resp.User.Country.split(':')[0];
								const country = resp.User.Country.split(':')[1];
								const stateCode = resp.User.Province.split(':')[0];
								const state = resp.User.Province.split(':')[1];
								const account = {
									user: {
										first_name: resp.User.FirstName,
										last_name: resp.User.LastName,
										address1: resp.User.Address1,
										address2: resp.User.Address2,
										city: resp.User.City,
										countryCode: countryCode,
										country: country,
										stateCode: stateCode,
										state: state,
										company_name: resp.User.CompanyName,
										email: resp.User.Email,
										phone:  resp.User.Phone,
										postal_code: resp.User.Postal,
										zip: resp.User.Postal
									},
									payments: resp.Payment.MethodsAvailable,
									accountId: resp.User.AccountId,
									currency: resp.Currency
								}
								this.setStoredAccount(account)
								if(cart.hasOwnProperty('CustomerSessionKey')) {
									this.updateCartData(cart.CustomerSessionKey)
								}
								observer.next(account);
								observer.complete();
							} else {	
								this.removeAuthInfo();
								observer.error('not authenticated')

							}
						
					})
				}
			} else {
				this.removeAuthInfo();
				observer.error('not authenticated');
			}
		});
	}

	public removeAuthInfo() {
		const cookieHostname = this.cookieHostname;
		this.cookieService.delete('AmpConnect','/',cookieHostname);
		this.ampConnectCookie = null;
		this.localStorageService.removeItem('op-account')
	}

	public logout() {
		const url = this.opApiUrl + '/api/user/logout';
		return new Observable((observer) => {

			this.apiService.http.post(url, this.headeroptions)
				.subscribe ( resp => {
					this.removeAuthInfo();
					observer.next('logged out')
					observer.complete();
				})
			});
	}

	private samePackageInfo = function(packageInfo) {
		const storedPackageInfo = this.getPackageInfo();		
		return _isEqual(storedPackageInfo, packageInfo)
	}

	public countryLookup(code = null) {
		const vm = this;
		return new Observable((observer) => {
			let url = this.opApiUrl + '/api/country-lookup'
			if(code) {
				url += '/' + code;
			}
			vm.apiService.http.get(url, this.headeroptions)
				.subscribe( 
				(resp) => {
					this.states = resp;
					observer.next(this.states)
					observer.complete()
				},
				(resp) => {
					console.log(resp)
					observer.next('failed')
					observer.error(resp)
				}
			);
		})
		
	}

	public fetchAmpCatalog = function(csk = null) {
		return new Observable((observer) => {
			const host = this.opApiUrl;
			const url = host + '/api/order/fetch-catalog';
			let updateCartCat = true;
			const cart = this.getStoredCart();
			if(cart && csk === null) {
				csk = cart.CustomerSessionKey;
			}
			let cartCat = this.getCartCatalog();
			if(cartCat.hasOwnProperty('data') && cart.hasOwnProperty('ttl')) {
				if((moment().toISOString() < moment(cart.ttl).toISOString())) {
					updateCartCat = false;
				}
			}
			
			if(updateCartCat === true) {
				const request = {
					CustomerSessionKey: csk,
				};
				this.apiService.http.post(url, request, this.headeroptions)
					.subscribe(resp => {
						if(resp.hasOwnProperty('success')) {
							if(resp.success === true) {
								cartCat = this.setCartCatalog(resp['AvailableProducts']);
								observer.next(cartCat);
								observer.complete()
							}
						}
					})
			} else {
				observer.next(cartCat);
				observer.complete()
			}
		})
	}

	public checkAvailability = function(domain, v2 = true) {
		const host = this.opApiUrl;
		let url = host + '/api/registrar-service/check-availability';
		if(v2 === true) {
			url += '-v2';
		}
		url += '?domain=' + domain;
		return this.apiService.http.get(url, this.headeroptions)
	}

	public checkEmail = function(email) {
		const host = this.opApiUrl;
		const cart = this.getStoredCart();
		let csk = '';
		const unixDate = Date.now();
		if(cart) {
			csk = cart.CustomerSessionKey;
		}
		const url = host + '/api/user/check-email';
		return this.apiService.http.post(url, {email: email, CustomerSessionKey: csk, timestamp: unixDate}, this.headeroptions)
	}

	public addToEvents(event,data) {
		const events = this.getStoredEvents();
		const storedCart = this.getStoredCart();
		let csk = null;
		if(storedCart.hasOwnProperty('CustomerSessionKey')) {
			csk = storedCart.CustomerSessionKey;
		}
		const newEvent =  {
			event: event,
			timeStamp: Date.now(),
		}
		if(event === 'cart:advance') {
			newEvent['from'] = data.from;
			newEvent['to'] = data.to;
			if(data['initial'] === true) {
				newEvent['CustomerSessionKey'] = csk;
			}
		}
		
		events.push(newEvent);
		this.setStoredEvents(events);
	}


	public saveCart(packages,data = {}) {
		if(packages) {
			this.saveCartDebounce(packages,data);
		}
	}
	
	public saveCartCall(packages,data) {
		const matomoInfo = this.getMatomoInfo();
		const events = this.getStoredEvents();
		const cart = this.getStoredCart();
		const newItems = this.buildCartItems(packages,cart)
		if(data.hasOwnProperty('email')) {
			cart.EmailAddress = data['email']
		}
		const request = {
			"CustomerSessionKey": cart.CustomerSessionKey,
			"Request": this.saveRequest,
			"Items": newItems,
			"Email": cart.EmailAddress,
			"Events": JSON.stringify(events),
			"MatomoInfo": matomoInfo
		}
		const host = this.opApiUrl;
		const url = host + '/api/order/save';
		this.apiService.http.post(url, request, this.headeroptions)
			.subscribe(resp => {
				cart['Items'] = resp['Items'];
				this.setStoredCart(cart);
				this.saveRequest = this.saveRequest +1;
			})
	}

	public buildCartItems(data, cart) {
		let tempId = 124;
		let items = [];
		const addonItems = data.filter(e => e.type === 'addon');
		if(!cart.hasOwnProperty('Items')) {
			cart['Items'] = {};
		}
		const savedCartItems = Object.keys(cart.Items).map((key) => cart.Items[key]);
		const hostingItems = data.filter(e => e.type === 'hosting');
		hostingItems.forEach(hosting => {
			const hostingAddons = [];
			const item = {
				Id:			'c' + tempId.toString(),
				ProductId:  hosting.productId,
				Quantity:   hosting.quantity,
				OwnedDomain: hosting.options.domain_name,
				DataCenter: hosting.options.data_center
			}
			const savedCartItem = savedCartItems.find(e => parseInt(e.ProductId,10) === parseInt(hosting.productId,10))
			if(hosting.hasOwnProperty('cartItemId')) {
				item.Id = hosting.cartItemId
			} else if(savedCartItem) {
				item.Id = savedCartItem.Id
			} else {
				tempId++;
			}
			addonItems.forEach(addon => {
				if(!addon.label.includes('Domain')) {
					const addonItem = {
						Id:			'c' + tempId.toString(),
						ProductId:  addon.productId,
						Quantity:   addon.quantity,
					}
					if(savedCartItem) {
						if(savedCartItem.hasOwnProperty('Addon')){
							if(typeof savedCartItem.Addon === 'object') {
								savedCartItem.Addon = Object.keys(savedCartItem.Addon).map((key) => savedCartItem.Addon[key])
							}
							const savedAddon = savedCartItem.Addon.find(e => parseInt(e.ProductId,10) === parseInt(addon.productId,10))
							if(savedAddon) {
								addonItem.Id = savedAddon.Id
							}
						}
					}
					if(addonItem.Id === "c" + tempId.toString()) {
						tempId++;
					}
					hostingAddons.push(addonItem)
				}
			})
			if(hostingAddons.length > 0) {
				item['Addon'] = hostingAddons;
			}
			items.push(item)
		})
		const domainItems = data.filter(e => e.type === 'domain');
		domainItems.forEach(domain => {
			let freeDomain = false;
			const domainAddons = [];
			if(domain.hasOwnProperty('discounts')) {
				if(domain.discounts.amount * 100 === domain.price_in_cents) {
					freeDomain = true;
				}
			}
			const item = {
				Id:			'c' + tempId.toString(),
				ProductId:  domain.productId,
				Quantity:   domain.quantity,
				Domain: domain.options.domain_name,
				PlanId: null,
				Free: freeDomain,
			}
			const savedCartItem = savedCartItems.find(e => parseInt(e.ProductId,10) === parseInt(domain.productId,10))
			if(savedCartItem) {
				item.Id = savedCartItem.Id
			} else {
				tempId++;
			}
			const possibleParentItems = items.filter(e => e.hasOwnProperty('DataCenter') && e.hasOwnProperty('OwnedDomain') )
			if(possibleParentItems) {
				const parentItem = possibleParentItems.find(e => e.OwnedDomain === item.Domain)
				if(parentItem) {
					item.PlanId = parentItem.Id;
					parentItem.PrimaryDomainId = item.Id;
					delete parentItem.OwnedDomain;
					items = items.map((itemInfo) => itemInfo.id === parentItem.Id ? parentItem : itemInfo);
				}
			}
			addonItems.forEach(addon => {
				if(addon.label.includes('Domain')) {
					const addonItem = {
						Id:			'c' + tempId.toString(),
						ProductId:  addon.productId,
						Quantity:   addon.quantity,
						ProductClass: "Domains"
					}
					if(savedCartItem) {
						if(savedCartItem.hasOwnProperty('Addon')) {
							if(typeof savedCartItem.Addon === 'object') {
								savedCartItem.Addon = Object.keys(savedCartItem.Addon).map((key) => savedCartItem.Addon[key])
							}
							const savedAddon = savedCartItem.Addon.find(e => parseInt(e.ProductId,10) === parseInt(addon.productId,10))
							if(savedAddon) {
								addonItem.Id = savedAddon.Id
							}
						}
					}
					if(addonItem.Id === "c" + tempId.toString()) {
						tempId++;
					}
					domainAddons.push(addonItem)
				}
			})
			if(domainAddons.length > 0) {
				item['Addon'] = domainAddons;
			}
			items.push(item)
		})
		return items;
	}

	public getCatalogAddons(catalog) {
		let catalogAddons = [];
		for (let i = 0; i < catalog['data'].length; i++) {
			const product = catalog['data'][i]
			if(product.hasOwnProperty('Addon')) {
				const addons = Object.keys(product['Addon']).map((key) => product['Addon'][key]);
				catalogAddons = catalogAddons.concat(addons)
			}
		}
		return catalogAddons;
	}

	public getMatomoInfo() {
		const matomoInfo = {
			cartUrl: window.location.href,
			visitorId: null
		}
		if(window.hasOwnProperty('Matomo')) {
			matomoInfo.visitorId = window['Matomo'].getTracker().getVisitorId()
		}
		return matomoInfo;		
	}

	public getGoogleEnhancedECommercePayload(data,cart) {
		const items = [];
		let affiliate = "";
		let campaign = "";
		const orderInfo = this.getOrderInfo();
		if(orderInfo.hasOwnProperty('affiliate')) {
			affiliate = orderInfo.affiliate;
		}
		if(orderInfo.hasOwnProperty('campaign')) {
			campaign = orderInfo.campaign;
		}
		
		const catalog = this.getCartCatalog();
		const catalogAddons = this.getCatalogAddons(catalog);

		let packageName = "";
		for (let i = 0; i < cart.cart.length; i++) {
			const cartItem = cart.cart[i];
			let catalogItem = catalog['data'].find(e => e.Id === cartItem.productId);
			if(catalogItem === undefined) {
				catalogItem = catalogAddons.find(a => a.Id === cartItem.productId)
			}
			if(catalogItem) {
				if(packageName === "") {
					packageName = catalogItem.PackageName;
				}
				
				const item = {
					item_id: cartItem.productId,
					item_name: catalogItem.ProductClass + " - " + catalogItem.Name + " - " + catalogItem.ProductTerm,
					affiliation: catalogItem.Brand,
					coupon: packageName, // campaign
					discount: catalogItem.DiscountAmount, //discount for item
					index: i, //item index
					item_brand: catalogItem.Brand,
					item_category: catalogItem.BaseType, // type
					item_category2: catalogItem.SubType, // secondary type
					item_category3: catalogItem.Name, // product name
					item_category4: catalogItem.ProductTerm, // term_length
					item_list_id: this.isAuthenticated() ? "authenticated" : 'unauthenticated',
					item_variant: false, // ExitIntentDiscount true/false,
					location_id: "ChIJVVbosh_AuokR9gnIrZKnLtQ",
					price: catalogItem.DiscountedPrice, // product price,
					quantity: cartItem.quantity,
					promotion_id: affiliate ? affiliate : "", // affiliates
					promotion_name: campaign ? campaign : cartItem.info.CampaignName, // campaign
				}
				items.push(item)
			}
		}
		
		const payload =  {
			event_action: 'purchase',
			send_to: "",
			ecommerce: {
				transaction_id: "T_" + data['ReceiptId'],// receiptId ???
				value: data['Total'],
				tax: data['Taxes'],
				coupon: packageName,
				currency: cart.currency,
				items: items
			}
		}
		return payload;
	}

}