<div class="ctw-w-full ctw-h-full ctw-flex ctw-flex-col ctw-min-h-screen">
    <central-header actions="amp" [status]="'success'" (action)="this.header($event)" breakpoint="6xl"></central-header>
    <div class="ctw-w-full ctw-flex ctw-flex-col"
		[class]="this.paymentStatus !== 'submitted' && !['showcart','success'].includes(this.status) ? 'ctw-hidden' : ''">
		<amp-stepper [steps]="steps" (changedStep)="changedStep($event)" class="animate__animated animate__fadeIn"
			[hidden]="this.status != 'showcart'" #stepper></amp-stepper>
		<amp-domain class="ctw-mx-3 ctw-mt-6 ctw-mb-16" [hidden]="this.hideDomainSelect" (domainInfo)="setDomainInfo($event)"
			#ampDomain (domainSelected)="domainSelected($event)"></amp-domain>
        <payment-form
			#payment
			class="ctw-mt-6 ctw-mb-16"
			(paymentBack)="paymentBack()"
			(paymentSuccess)="paymentSuccess()"
			(paymentFailure)="this.status = 'showcart';this.paymentStatus = 'pending'"
			(paymentSubmit)="this.status = 'loading';this.paymentStatus = 'submitted'"
			(termChanged)="this.termUpdated()"
			[includeReceiptQuery]="true"
			[excludedInputs]="['email']"
			[includeBackButton]="false"
            [checkoutMode]="'amp'"
			[disablePostPurchase]="true"
			[hideGuarantee]="true"
			[hidden]="this.hideCart === true"
		>
		</payment-form>
    </div>
	<div class="ctw-w-full ctw-flex ctw-flex-col ctw-mt-5" *ngIf="this.status === 'failed'">
		<failed-request />
	</div>
	<div class="ctw-w-full ctw-flex ctw-flex-col ctw-mt-5" *ngIf="this.status === 'redirect_to_old_cart'">
		<mat-card class="warning complete-notification card-small ctw-my-16 oops">
			<div class="animate__animated animate__fadeIn">
				<h3 class="">Sorry for the delay.</h3>
				<p>
					You will be redirected shortly to the appropriate cart...
				</p>
			</div>
		</mat-card>
	</div>
	<progress-message *ngIf="this.paymentStatus !== 'submitted' && this.status === 'loading'" [progressMessages]="this.progressMessages" />
	<central-footer class="ctw-w-full" breakpoint="6xl" actions="amp"></central-footer>
</div>
