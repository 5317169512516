import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
	selector: 'amp-account-auth',
	templateUrl: 'account-auth.component.html',
	styleUrls: ['account-auth.component.scss'],
})
export class AmpAccountAuthComponent implements OnInit, AfterViewInit {
	@ViewChild('newPassword') public newPassword: any;
	@ViewChild('email') public email: any;
	public isValid;
	public status = 'pending';
	public accountType = 'new';
	public password;
	public contactEmail = '';
	public emailChecked = false;
	public checkingEmail = false;
	public showForgotPassword = false;
	public forgotPasswordError = '';
	public forgotPasswordSent = false;
	public username;
	public isAuthenticated = false;
	public emailExists = false;
	public isInternalIp = false;
	public allowCheck = false;
	public checkAvailable = false;
	public existingEmailPrevention = true;
	public incorrectCredentials = false;
	public showTwoFactor = false;
	public twoFaType;
	public twoFaError;
	public twoFaLast;
	public code;
	public twoFaAccountId;
	public disableNewCode = false;
	public countries = [];
	@Input() public readOnlyForm = false;
	@Input() public includeBackButton = false;
	@Input() public contactInputs = {};
	@Output() public checkedEmail = new EventEmitter();
	@Output() public ampLogin = new EventEmitter();
	@Output() public ampLogout = new EventEmitter();
	@Output() public accountTypeChanged = new EventEmitter();
	@Output() public goBack = new EventEmitter();

	public inputType = 'password';

	public constructor(public ampOpApiService: AmpOpApiService, private snackBar: MatSnackBar) {}

	ngOnInit(): void {
		this.ampOpApiService.countryLookup()
			.subscribe(resp => {
				if(resp.hasOwnProperty('_embedded')) {
					if(resp['_embedded'].hasOwnProperty('country')) {
						this.countries = resp['_embedded']['country'];
					}
				}
			})
	}

	ngAfterViewInit(): void {
		this.isAuthenticated = this.ampOpApiService.isAuthenticated();
		if(this.isAuthenticated) {
			this.ampOpApiService.getAccountInfo().subscribe(
				(resp) => {
					if(resp.hasOwnProperty('user')) {
						this.readOnlyForm = true;
						this.setAccountType('existing')
						this.contactEmail = resp['user'].email;
						this.emailExists = true;
						this.emailChecked = true;
					} else {
						this.logout('new');
					}
					if(resp.hasOwnProperty('checkAvailable')) {
						if(resp['checkAvailable']) {
							this.checkAvailable = true;
							this.allowCheck = true;
						} else {
							this.checkAvailable = false;
							this.allowCheck = false;
						}
					} else {
						this.checkAvailable = false;
						this.allowCheck = false;
					}
				},
				(resp) => {
					this.logout('new');
				}
			);			
		} else {
			this.logout('new');
		}
	}

	/**
	 * Is an input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInput(input: any): boolean {
		const dirty =
			(input.dirty && input.touched);
		return dirty && !input.valid;
	}

	/**
	 * Is a generic input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInputState(input: any): boolean {
		return (!input.valid && this.invalidInput(input));
	}

	public getInfo() {
		const info = {
			email: this.contactEmail,
		}
		if(this.accountType === 'new') {
			info['password'] = this.newPassword;
		}
		return info
	}

	public getCountryName(code) {
		const country = this.countries.find(c => c.Code === code);
		return (country && country.hasOwnProperty('FullName')) ? country.FullName : code;
	}

	public setAccountType(type) {
		this.accountType = type;
		this.showForgotPassword = false;
		if(type === 'new' && this.emailChecked && this.emailExists) {
			this.contactEmail = '';
			this.password = '';
			this.emailChecked = false;
			this.emailExists = false;
		}
		this.clearErrors();
		this.accountTypeChanged.emit();
	}

	public clearErrors() {
		this.forgotPasswordError = '';
		this.email.control.status = "VALID";
		this.incorrectCredentials = false;
		this.forgotPasswordSent = false;
		this.twoFaError = false;
	}

	public forgotPassword() {
		this.clearErrors();
		this.status = 'submitted';
		if(this.contactEmail && this.username) {
			const request = {email: this.contactEmail, username: this.username}
			this.ampOpApiService.forgotPassword(request)
				.subscribe({
					next: () => {
						this.forgotPasswordSent = true;
						this.status = 'pending';
						this.showForgotPassword = false;
						this.clearErrors();
						this.username = '';
						const linkSentNotice = `Your Password Reset link has been e-mailed to the Primary E-mail Address on file for your account.
							By clicking the link in that e-mail, you will be directed to a secure page to reset your account password.`;
						this.showSnackBar(linkSentNotice);
					},
					error: (e) => {
						this.status = 'pending';
						this.showSnackBar(e.error);
					}
				})
		}
	}

	public showSnackBar(notice) {
		const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
		const verticalPosition: MatSnackBarVerticalPosition = 'top';
		return this.snackBar.open(notice, 'Dismiss', {
			duration: 7000,
			horizontalPosition,
			verticalPosition,
			panelClass: ['mat-toolbar', 'mat-accent'],
		})
	}


	public clearTwoFactorInfo() {
		this.twoFaType = '';
		this.twoFaLast = '';
		this.code = '';
		this.showTwoFactor = false;
		this.twoFaAccountId = '';
	}

	public authenticateAccount() {
		this.status = 'submitted';
		this.clearErrors();
		this.ampOpApiService.cartAuthenticate({email: this.contactEmail, password: this.password})
			.subscribe({
				complete: () => {
					this.isAuthenticated = true;
					this.readOnlyForm = true;
					this.status = 'success';
					this.clearTwoFactorInfo();
					this.ampLogin.emit();
				},
				error: () => {
					this.status = 'failed';
					this.incorrectCredentials = true;
					this.email.control.status = "INVALID";
				}
			})	
	}

	public handleAuthentication() {
		this.status = 'submitted';
		this.clearErrors();
		this.ampOpApiService.checkTwoFactor({email: this.contactEmail}).subscribe({
			next:(resp) => {
				if(resp['hasTwoFactor'] === true) {
					this.twoFaType = resp['type'];
					this.twoFaLast = resp['lastFour'];
					this.twoFaAccountId = resp['accountId'];
					this.status = 'pending';
					this.showTwoFactor = true;
					if(['text','dormant'].includes(this.twoFaType)) {
						this.disableNewCode = true;
						setTimeout(() => {
							this.disableNewCode = false;
						}, 10000);
					}
				} else {
					this.authenticateAccount();
				}
			},
			error: (resp) => {
				this.status = 'failed';
				console.log(resp)
			}
		})
			
	}

	public verifyTwoFactor() {
		if(this.code.trim() !== '') {
			this.status = 'submitted';
			this.clearErrors();
			this.ampOpApiService.verifyTwoFactor({email: this.contactEmail, code: this.code.trim()}).subscribe({
				complete:() => {
					this.status = 'pending';
					this.authenticateAccount();
				},
				error: (resp) => {
					this.clearTwoFactorInfo();
					this.status = 'failed';
					this.incorrectCredentials = true;
					this.email.control.status = "INVALID";
				}
			})
		}
			
	}

	public logout(type = 'existing') {
		this.status = 'submitted';
		this.clearErrors();
		this.ampOpApiService.logout()
			.subscribe(resp => {
				this.status = 'success';
				this.isAuthenticated = false;
				this.readOnlyForm = false;
				this.emailChecked = false;
				this.contactEmail = '';
				this.email.control.status = "VALID"
				this.password = '';
				this.setAccountType(type);
				this.ampLogout.emit();
			})
	}

	public showBackButton() {
		if(this.accountType === 'new') {
			return !this.emailChecked && this.existingEmailPrevention;
		} else {
			return !this.isAuthenticated;
		}
	}

	public sendNewCode() {
		if(['text','dormant'].includes(this.twoFaType)) {
			const codeRequest = {
				email: this.contactEmail,
				type: this.twoFaType,
				accountId: this.twoFaAccountId
			}
			if(this.twoFaType === 'dormant') {
				codeRequest['send'] = false;
				codeRequest['comment'] = true
			}
			this.ampOpApiService.sendVerificationCode(codeRequest).subscribe( () => {
				this.showSnackBar("New Code Sent!");
				this.disableNewCode = true;
				setTimeout(() => {
					this.disableNewCode = false;
				}, 10000);
			})
		}
	}

	public validEmail() {
		const email = this.contactEmail.trim() || '';
		if ( email ) {
			const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
			if (re.test(email)) {
				return email;
			}
		}
		return '';
	}

	public trimText(text: string): string {
		return text.length > 30 ? text.substr(0, 30) + '...' : text;
	}

	public checkEmail(oncontinue = false) {
		if((this.accountType === 'new' && this.emailChecked) || oncontinue === true) { 
			this.clearErrors();
			const email = this.validEmail();
			if(email) {
				this.checkingEmail = true;
				this.ampOpApiService.checkEmail(email)
					.subscribe(
						(resp) => {
							this.allowCheck = resp.allowCheck;
							this.isInternalIp = resp.isInternalIp;
							this.existingEmailPrevention = resp.existingEmailPrevention;
							this.emailChecked = true;
							this.checkingEmail = false;
							if(resp.isCustomer){
								this.emailExists = true;
							} else {
								this.emailExists = false;	
							}
							this.checkedEmail.emit();
						},
						(error) => {
							this.emailChecked = false;
							this.emailExists = false;
							this.isInternalIp = false;
							this.existingEmailPrevention = true;
							this.checkingEmail = false;
						})
			} else {
				this.emailChecked = false;
				this.emailExists = false;
				this.isInternalIp = false;
				this.existingEmailPrevention = true;
			}
		}
	}
}