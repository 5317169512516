import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationService, GoogleAnalyticsService, OptimizelyService } from '@central/ng-shared';
import { CookieService } from 'ngx-cookie-service';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import { AmpDomainComponent } from '../amp-domain/amp-domain.component';

@Component({
	selector: 'amp-checkout',
	templateUrl: 'checkout.component.html',
	styleUrls: ['checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
	@ViewChild('payment', { static: true }) public payment: any;
	@ViewChild('stepper') public stepper: any;
	@ViewChild(AmpDomainComponent, {static: true}) public ampDomain: AmpDomainComponent;
	public status = 'loading';
	public cartStatus = 'pending';
	public paymentStatus = 'pending';
	public savedCartLoaded = false;
	public iridCookie: string;
	public irmpCookie: string;
	public ampConnectCookie: string;
	public domainsInfo = {};
	public domainName = '';
	public domain = {};
	public initialAdvance = true;
	public accountInfo = {};
	public hideCart = true;
	public freeDomain = false;
	public requiresDomain = false;
	public hideDomainSelect = true;
	public ppHost = '';
	public opHost = '';
	public chatUrl = '';
	public steps = [
		{ step: 1, title: 'HOSTING', isEditable: false, completed: true, disabled:true, selected: false},
		{ step: 2, title: 'DOMAIN', isEditable: true, completed: false, disabled:false, selected: false},
		{ step: 3, title: 'COMPLETE', isEditable: false, completed: false, disabled:false, selected: false},
	];
	public progressMessages = [
		{ msg: 'Checking cart info', time: 2000, type: 'any'},
		{ msg: 'Loading cart details', time: 4000, type: 'any'},
		{ msg: 'Finishing up...', time: 0, type: 'any'},
	];

	public constructor(
		public configService: ConfigurationService,
		public ampOpApiService: AmpOpApiService,
		public localStorage: AmpLocalStorageService,
		private route: ActivatedRoute,
		private router: Router,
		private optimizelyService: OptimizelyService,
		private cookieService: CookieService,
		private cdr :ChangeDetectorRef,
		public googleAnalytics: GoogleAnalyticsService,
		private location: Location,
	) {
		this.ppHost = this.configService.config.powerPanel.ampHost;
		this.opHost = this.configService.config.powerPanel.opHost;
		this.chatUrl = this.ppHost + this.configService.config.powerPanel.chatUrl;
		if(this.cookieService.check('irid')) {
			this.iridCookie = this.cookieService.get('irid');
		}
		if(this.cookieService.check('irmp')) {
			this.irmpCookie = this.cookieService.get('irmp');
		}
	}

	public ngOnInit() {
		const packageString = this.route.snapshot.params['products'];
		const cskParam = this.route.snapshot.params['csk'];
		if(packageString) {
			this.handlePackages(packageString)
		} else {
			this.handleCsk(cskParam)
		}
	}

	

	public ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	public handleCsk(cskParam) {
		let csk;
		let orderSession = false;
		const cartInfo = this.ampOpApiService.getStoredCart();
		if(cartInfo.hasOwnProperty('CustomerSessionKey')) {
			csk = cartInfo.CustomerSessionKey;
		}
		if(cskParam) {
			csk = cskParam;
			orderSession = true;
		}
		if(csk) {
			this.ampOpApiService.getOPCatalogFromCartInfo(csk, orderSession)
					.subscribe(
					(data) => {
						if(data.hasOwnProperty('redirect')) {
							this.redirectToOldOpCart(csk)
						} else {
							if(this.route.snapshot.fragment !== 'domain') {
								history.pushState(null,null,'/amp/checkout#domain')
							}
							this.loadCart(data)
						}
					})
		} else {
			this.status = 'failed';
			this.failureRedirect();
		}
	}

	public failureRedirect() {
		this.status = 'failed';
		this.ampOpApiService.clearStoredInfo();
		setTimeout( () => {
			window.location.href = 'https://www.inmotionhosting.com';
		}, 3000 );
	}

	public redirectToOldOpCart(csk) {
		this.status = 'redirect_to_old_cart';
		this.ampOpApiService.clearStoredInfo();
		setTimeout( () => {
			window.location.href = this.opHost + '/order-process#order-session/' + csk;
		}, 1000 );
	}

	public handlePackages(packageString) {
		const campaign = this.route.snapshot.queryParams['campaign'];
		const affiliates = this.route.snapshot.queryParams['affiliates'];
		const referrer = window.document.referrer;
		this.ampOpApiService.setOrderInfo(affiliates, campaign, referrer);
		history.pushState(null,null,'/amp/checkout#domain')
		const packageInfo = this.ampOpApiService.parsePackageInfo(packageString);
		this.ampOpApiService.getOPCatalogFromPackageString(packageInfo)
			.subscribe(
			(data) => {
				this.loadCart(data)
			})
	}

	public loadCart(data) {
		if(data['packages'].hasOwnProperty('packageFound')) {
			if(data['packages']['packageFound'] === true) {
				this.payment.useCartService = false
				this.payment.packages = [...data['packages']['packages']];
				this.payment.term = data['packages']['term'];
				this.payment.ampTerms = data['packages']['terms'];
				this.ampDomain.freeDomain = data['packages']['freeDomain'];
				this.freeDomain = data['packages']['freeDomain'];
				this.requiresDomain = data['packages']['requiresDomain'];
				if(this.requiresDomain) {
					this.hideDomainSelect = false;
				}
				
				this.payment.ampDomain = {name: this.domainName, freeDomain: data['packages']['freeDomain']};
				this.status = 'showcart';
				if(data.hasOwnProperty('cart')) {
					this.ampDomain.cartRefId = data['cart']['RefId'];
					this.ampDomain.cartId =  data['cart']['Id'];
					this.cartStatus = 'success';
					if(data['packages']['domainName'] !== null) {
						if(data['packages']['purchasedDomain']) {
							this.ampDomain.domainName =  data['packages']['purchasedDomain'];
							this.ampDomain.checkingSavedDomainAvailability = true;
							this.ampDomain.updateDomainAvailability(true);
						} else if(data['packages']['domainName'].includes('.temporary.link')) {
							this.ampDomain.setDomainSelection(true);
						} else {
							this.ampDomain.domainSelectionType = 'existing';
							this.ampDomain.handleDomainType();
							this.ampDomain.domainName =  data['packages']['domainName'];
							this.ampDomain.domainChange({});
							this.ampDomain.setDomainSelection(false);
						}
						
					}
					
				} else {
					this.ampOpApiService.createCart(data['packageInfo']).subscribe(
						(cart) => {
							if(cart) {
								this.ampDomain.cartRefId = cart['RefId'];
								this.ampDomain.cartId = cart['Id'];
								this.cartStatus = 'success';
							}
						}
					)
				}
				
			} else {
				this.status = 'failed';
			}

		} else {
			this.status = 'failed';
		}
		if(this.status === 'failed') {
			this.failureRedirect();
		}
	}

	public setDomainInfo(domainInfo) {
		this.domainsInfo = domainInfo;
	}

	public domainSelected(domain, autoaddon = true) {
		let advanceStepper = true;
		if(domain === 'reset domain') {
			domain = this.domain;
			this.initialAdvance = false;
			advanceStepper = false;
		} else {
			this.domain = domain;
		}
		const privIndx = this.payment.packages.findIndex(e => e.label === 'Domain Privacy');
		if(privIndx > -1) {
			this.payment.packages.splice(privIndx, 1);
		}
		const domainIndx = this.payment.packages.findIndex(e => e.type === 'domain');
		if(domainIndx > -1) {
			this.payment.packages.splice(domainIndx, 1);
		}
		const hosting = this.payment.packages.find(e => e.type === 'hosting');
		this.ampDomain.freeDomain = hosting.info.FreeDomain;

		if(domain.hasOwnProperty('price')) {

			let domainProduct = this.domainsInfo['data'].find(product => product.PackageToProductOfferingId === domain.id);
			if(domainProduct) {
				domainProduct.PackageToProductOfferingId = domain.id;
				domainProduct.Price = domain.price;
				domainProduct.DiscountPrice = domain.price;
				if(domain.info.freeEligible === true && hosting.info.FreeDomain === true) {
					this.freeDomain = true;
					this.ampDomain.freeDomain = true;
					domainProduct.DiscountPrice = "0.00";
				} else {
					this.freeDomain = false;
					this.ampDomain.freeDomain = false;
				}
				domainProduct = this.ampOpApiService.createCatalogPackage(domainProduct, domain.name);

				domainProduct.planCode = domain.packageId + '_' + domain.id;
				domainProduct.code = domain.packageId + '_' + domain.id;
				domainProduct['options']['price'] = domain.price * 100;
				let privacyAddon = null;
				if(domain.info.privacyEligible === true) {
					domainProduct['addons'].forEach(v => {
						if(v.AddonAutoSelected) {
							const addon = this.ampOpApiService.createCatalogPackage(v, domain.name);
							addon['selected'] = true;
							privacyAddon = addon;
						}
					})
				} else {
					domainProduct.addons = [];
				}
				this.payment.packages.push(domainProduct);
				if(privacyAddon && autoaddon) {
					this.payment.packages.push(privacyAddon)
				}
			}
		}
		this.domainName = domain.name;
		this.payment.ampDomain = {...domain, freeDomain: this.freeDomain};
		const hostingProduct = this.payment.packages.findIndex(product => product.type === 'hosting')
		if(hostingProduct !== -1) {
			this.payment.packages[hostingProduct]['options']['domain_name'] = this.domainName;
		}
		this.payment.cart?.fetchCatalog();
		if(advanceStepper) {
			this.stepper.next();
			history.pushState(null,null,'/amp/checkout#complete')
			this.ampOpApiService.addToEvents('cart:advance', {from: 'domain', to: 'billing', initial: this.initialAdvance});
		}
		this.hideDomainSelect = true;
		this.hideCart = false;

		if (this.configService.config['googleAnalytics'].routeOverrides) {
			const override = this.configService.config['googleAnalytics'].routeOverrides.find(
				item => this.location.path().startsWith(item.route)
			);
			this.googleAnalytics.trackConversion({
				send_to: override?.purchaseStart ?? ''
			});
		} else {
			this.googleAnalytics.trackConversion({
				send_to: this.configService.config.googleAnalytics.purchaseStart ?? ''
			});
		}
		if(window.hasOwnProperty('optimizely')) {
			this.optimizelyService.send('trackEvent', 'entered-cart');
		}
	}

	public changedStep(step) {
		if(this.steps[step].title === 'DOMAIN') {
			history.pushState(null,null,'/amp/checkout#domain')
			if(this.domainName === this.ampDomain.tempDomainName) {
				this.ampDomain.domainName = '';
			} else if(this.ampDomain.domainName !== '' && this.ampDomain.domainSelectionType === 'new') {
				this.payment.packages = this.payment.packages.filter(e => e.type !== 'domain')
			}
			this.ampOpApiService.addToEvents('cart:advance', {from: 'billing', to: 'domain', initial: false});

			this.hideCart = true;
			this.hideDomainSelect = false;
		}
	}

	public paymentBack() {
		this.stepper.previous();
	}

	public paymentSuccess() {
		this.status = 'success';
		history.pushState(null,null,'/amp/checkout#purchase-success')
	}

	public termUpdated() {
		this.domainSelected('reset domain');
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'openchat':
				this.openChat();
				break;
		}
	}

	public openChat() {
		const screenWidth        = screen.width;
		const screenWidthPercent = 0.25 * screenWidth;
		const minWidth           = 520;
		let width;

		const screenHeight       = 0.78 * screen.availHeight;
		let height;

		const chatParams = new URLSearchParams({
			option: 'sales',
			url: window.location.href
		});

		const chatUrl = this.chatUrl + '?' + chatParams.toString();

		if(screenWidthPercent < minWidth) {
			width = minWidth;
		} else {
			width = screenWidthPercent;
		}

		if(screenHeight > 850) {
			height = 850;
		} else {
			height = screenHeight;
		}

		window.open(chatUrl, 'AMP Chat', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height);
	}
}
