<dash-card disabled="1" [cardTitle]=" this.checkoutMode === 'amp' ? 'Order Summary' : 'Cart'"
	[subTitle]="[this.subtitle]" icon="shopping_cart">
	<div *ngIf="checkoutMode === 'amp' && ampCartInfo.hasOwnProperty('RefId')"
		class="ctw-absolute ctw-right-4 ctw-top-3.5 ctw-italic ctw-text-sm">
		My Cart #{{this.ampCartInfo.RefId}}
	</div>
	<mat-progress-bar *ngIf="this.status === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
	<ng-container *ngIf="status === 'failed'">
		<p><strong>Sorry! An error occurred.</strong></p>
		<p>
			We were unable to retrieve your requested products. Please go back
			and try again.
		</p>
	</ng-container>
	<ng-container *ngIf="status === 'success'">
		<mat-form-field appearance="fill" class="currency-selector" *ngIf="checkoutMode === 'default'">
			<mat-label>Currency</mat-label>
			<mat-select [(ngModel)]="currency" (ngModelChange)="currencySelected($event)" [disabled]="true"
				class="ctw-px-4">
				<mat-option *ngFor="let selected of this.currencies" value={{selected}}>{{selected}}</mat-option>
			</mat-select>
		</mat-form-field>
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="ctw-mt-4">
			<!--- Note that these columns can be defined in any order.
		        The actual rendered columns are set as a property on the row definition" -->

			<!-- Quantity Column -->
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>QTY</th>
				<td mat-cell *matCellDef="let element">
					<mat-form-field class="ctw-w-10 ctw-m-0 ctw-text-center">
						<input (input)="
								this.changeQuantity(
									quantity.value,
									element.code,
									quantity
								)
							" #quantity matInput [disabled]="
								!element.allows_multiple ||
								this.lockCartQuantity
							" type="number" min="1" max="99" value="{{ element.quantity }}" />
					</mat-form-field>
				</td>
			</ng-container>

			<!-- Product Column -->
			<ng-container matColumnDef="product">
				<th mat-header-cell *matHeaderCellDef>PRODUCT</th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="this.checkoutMode === 'default'" class="notranslate">
						{{
						element.product
						.replace(' - Yearly', '')
						.replace(' - Monthly', '')
						.replace('WordPress Hosting', 'UltraStack VPS')
						}}
					</div>
					<div *ngIf="this.checkoutMode === 'amp'" class="notranslate">
						{{element.product}} <span *ngIf="element.amp_product_type === 'hosting'" class="ctw-italic"> -
							Web Hosting</span>
					</div>
					<div *ngIf="element.domain" class="ctw-font-bold ctw-italic ctw-text-sm notranslate">
						{{ element.domain }}
					</div>
					<div class="ctw-text-imh-gray-200 ctw-italic ctw-text-xs">
						<span>{{ element.is_recurring ? element.term.replace('s', '') : '' }}</span>
						<span>{{ element.is_recurring ? ' Subscription' : 'One-time Fee' }}</span>
					</div>
				</td>
			</ng-container>

			<!-- Price Column -->
			<ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef>PRICE</th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="this.checkoutMode === 'default'" class=" notranslate">
						{{ getFormattedCurrency((element.price * element.quantity)) }}
					</div>
					<div class="ctw-flex ctw-flex-col notranslate" *ngIf="this.checkoutMode === 'amp'">
						<span class="ctw-font-bold" *ngIf="productHasDiscount(element)">
							<span>
								{{ displayDiscountedPrice(element) > 0 ?
								getFormattedCurrency(displayDiscountedPrice(element)) : 'FREE'}}
							</span>
						</span>
						<span [class]="productHasDiscount(element) ? 'ctw-line-through ctw-text-sm' : ''">
							{{ getFormattedCurrency(element.price * element.quantity) }}
						</span>
						<span class="discount ctw-text-imh-green ctw-text-sm"
							*ngIf="productHasDiscount(element) && element.amp_product_type !== 'domain'">
							{{(element.discounts.amount / element.price * 100).toFixed(0)}}% off
						</span>
					</div>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<div class="ctw-px-6 ctw-py-4">
			<div *ngIf="this.hasDiscount">
				<div class="ctw-text-sm ctw-mb-4">
					<div>
						Subtotal
						<span class="ctw-float-right notranslate">{{ getFormattedCurrency(this.subTotal) }}</span>
					</div>
					<div class="discount ctw-text-imh-green">
						<span *ngIf="checkoutMode === 'default'">{{ this.discount.value }}% Discount</span>
						<span *ngIf="checkoutMode === 'amp'">Total Savings</span>
						<span class="ctw-float-right notranslate">{{ getFormattedCurrency(this.discount.amount)
							}}</span>
					</div>
				</div>
				<div class="ctw-border-b ctw-border-solid ctw-border-[rgba(0,0,0,0.12)] ctw--ml-6 ctw--mr-6 ctw-mb-4">
				</div>
			</div>

			<div class="final" *ngIf="this.isUpdatePlan">
				<strong>Credit</strong>
				<span class="ctw-float-right ctw-text-imh-green notranslate">{{ getFormattedCurrency(this.updateCredit)
					}}</span>
			</div>
			<div class="ctw-font-bold">
				Total
				<span class="ctw-float-right notranslate">{{ getFormattedCurrency(this.total) }}</span>
			</div>
		</div>
	</ng-container>
</dash-card>
<ng-conatainer *ngIf="allowsTermDiscount && checkoutMode === 'default'">
	<dash-card disabled="1" cardTitle="Renewal Period" icon="date_range"
		*ngIf="status === 'success' && (this.yearlyEnabled || this.monthlyEnabled)">
		<div class="ctw-px-5 ctw-pt-5">
			<mat-radio-group aria-label="Select an option" [(ngModel)]="this.term" (change)="this.updateProducts()"
				class="ctw-flex ctw-flex-col ctw-items-start">
				<mat-radio-button class="ctw-mb-5" value="yearly" [disabled]="!this.yearlyEnabled"
					[class.ctw-hidden]="!this.yearlyEnabled">Yearly -
					<span class="ctw-text-imh-green ctw-font-bold">2 months FREE!</span></mat-radio-button>
				<mat-radio-button class="ctw-mb-5" value="monthly" [disabled]="!this.monthlyEnabled"
					[class.ctw-hidden]="!this.monthlyEnabled">Monthly</mat-radio-button>
			</mat-radio-group>
		</div>
	</dash-card>
	<dash-card [hidden]="this.dataCenters.length === 0 || this.isUpdatePlan" disabled="1" cardTitle="Data Center"
		icon="location_on" class="{{ this.cardClass }} data-center pi">
		<div class="ctw-shadow-lg ctw-rounded-b-lg" *ngIf="this.dataCenters.length > 0">
			<mat-button-toggle-group class="ctw-grid sm:ctw-grid-cols-3 ctw-border-none" #group="matButtonToggleGroup"
				[(ngModel)]="this.dataCenter" name="datacenter" aria-label="Data Center"
				(change)="this.updateProducts()">
				<mat-button-toggle [value]="2" [disabled]="!this.dataCenters.includes('lax')">
					<div class="ctw-flex">
						<span class="us flag"></span>
						<span>US West</span>
					</div>
				</mat-button-toggle>
				<mat-button-toggle [value]="1" [disabled]="!this.dataCenters.includes('iad')">
					<div class="ctw-flex">
						<span class="us flag"></span>
						<span>US East</span>
					</div>
				</mat-button-toggle>
				<mat-button-toggle [value]="3" [disabled]="!this.dataCenters.includes('ams')">
					<div class="ctw-flex">
						<span class="eu flag"></span>
						<span>EU</span>
					</div>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</dash-card>
</ng-conatainer>

<ng-conatainer *ngIf="checkoutMode === 'amp' && status === 'success'">
	<dash-card disabled="1" cardTitle="Change Term Length" icon="date_range">
		<div class="ctw-px-5 ctw-pt-5">
			<mat-radio-group aria-label="Select an option" [(ngModel)]="this.term" (change)="this.updateTerms()"
				class="ctw-flex ctw-flex-col ctw-items-start">
				<mat-radio-button class="ctw-mb-5" [value]="ampTerm.planCode" *ngFor="let ampTerm of this.ampTerms">
					<span class="ctw-flex ctw-flex-wrap">
						<span class="ctw-flex">
							<span>{{ ampTerm.plan_interval_length}} {{ampTerm.plan_interval_unit_display}} - </span>
							<span *ngIf="this.termHasDiscount(ampTerm) === false" class="ctw-ml-1 notranslate">
								<ng-container>$</ng-container>{{this.showTermPrice(ampTerm)}}/mo
							</span>
							<span class="ctw-text-imh-green ctw-font-bold ctw-ml-1 notranslate"
								*ngIf="this.termHasDiscount(ampTerm)">
								<ng-container>$</ng-container>{{this.showTermPrice(ampTerm, true) }}/mo
								<span>({{this.displayDiscountPercent(ampTerm)}}% off)</span>
							</span>
						</span>
						<span class="ctw-italic ctw-text-sm ctw-m-1" *ngIf="this.termHasDiscount(ampTerm)">
							- renews at
							<span class="notranslate">
								<ng-container>$</ng-container>{{this.showTermPrice(ampTerm)}}/mo
							</span>
						</span>
					</span>
				</mat-radio-button>
			</mat-radio-group>
		</div>
	</dash-card>
	<dash-card [hidden]="checkoutMode === 'default' || this.dataCenters.length === 0" disabled="1"
		cardTitle="Data Center" icon="location_on" class="{{ this.cardClass }} data-center">
		<div class="ctw-shadow-lg ctw-rounded-b-lg" *ngIf="this.dataCenters.length > 0">
			<mat-button-toggle-group class="ctw-grid sm:ctw-grid-cols-3 ctw-border-none" #group="matButtonToggleGroup"
				[(ngModel)]="this.dataCenter" name="datacenter" aria-label="Data Center" (change)="this.updateProducts()">
				<mat-button-toggle [value]="2">
					<div class="ctw-flex">
						<span class="us flag"></span>
						<span>US West</span>
					</div>
				</mat-button-toggle>
				<mat-button-toggle [value]="1">
					<div class="ctw-flex">
						<span class="us flag"></span>
						<span>US East/Central</span>
					</div>
				</mat-button-toggle>
				<mat-button-toggle [value]="3">
					<div class="ctw-flex">
						<span class="eu flag"></span>
						<span>EU Central</span>
					</div>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</dash-card>
	<dash-card disabled="1" cardTitle="Add On Services" icon="extension" *ngIf="availableAddons.length > 0">
		<div class="ctw-px-5 ctw-pt-5">
			<div *ngFor="let addon of sortedAddons()" class="ctw-flex ctw-flex-col ctw-pb-5">
				<div class="ctw-flex ctw-flex ctw-justify-between">
					<mat-checkbox [checked]="isSelected(addon)" (change)="changeAddon($event.checked,addon)"><span
							class="ctw-font-bold">{{ addon.ProductName }}</span></mat-checkbox>
					<div>
						<span class="notranslate"><ng-container>$</ng-container>{{perTermPricing(addon)}}</span> /mo
						<div class="ctw-text-xs">
							<div *ngIf="addon.AllowedQuantity > 1">
								per qty
							</div>
							<div *ngIf="addon.AllowedQuantity === 1">
								billed {{addon.TermType}}ly
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="ctw-ml-7 ctw-max-w-sm">
						{{ getAddonInfo(addon)}}
					</div>
				</div>
			</div>
		</div>
	</dash-card>
</ng-conatainer>
